<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="form_box">
			<el-form ref="form" :model="form" label-width="80px">
				<el-form-item label="真实姓名" prop="real_name" :rules="rules.required">
					<el-input v-model="form.real_name" placeholder="请输入会员姓名"></el-input>
				</el-form-item>
				<el-form-item label="会员性别" prop="gender" :rules="rules.required">
					<el-radio v-model="form.gender" :label="0">女</el-radio>
					<el-radio v-model="form.gender" :label="1">男</el-radio>
				</el-form-item>
				<el-form-item label="手机号码" prop="phone" :rules="[rules.required,rules.phone]">
					<el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
				</el-form-item>
				<el-form-item label="会员生日" prop="birthday">
					<el-date-picker v-model="form.birthday" type="date" placeholder="选择生日" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="所在地区" prop="location">
					<el-cascader v-model="form.location" :options="xzqhOptions" @change="locationChange" clearable></el-cascader>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="form.remark" type="textarea" :autosize="{ minRows: 4}"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit()">保存</el-button>
					<el-button @click="$router.back()">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../common/common.rules";
	// 行政区划npm版本
	import ChinaLocation from "china-location";
	import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				rules,
				title: this.$route.params.id ? "编辑会员" : "新增会员",
				form: {
					id: this.$route.params.id,
					avatar: undefined, // 微信头像
					username: undefined, // 微信昵称
					real_name: undefined, // 真实姓名
					gender: 0, // 性别
					phone: undefined, // 手机号
					birthday: undefined, // 会员生日
					province: undefined, // 省
					city: undefined, // 市
					district: undefined, // 区
					remark: undefined, // 备注
					location: []
				}
			};
		},
		computed: {
			xzqhOptions() {
				// return xzqh.addrData();
				let chinaLocation = new ChinaLocation(list);
				// console.log(list)
				return this.formatLocation(chinaLocation.locationData);
			},
			isEdit() {
				return Boolean(this.form.id)
			}
		},
		mounted() {
			// 获取当前编辑的会员信息
			if (this.form.id) {
				this.$api.member.getMember({
					id: this.form.id
				}).then(res => {
					this.form.real_name = res.data.real_name; // 真实姓名
					this.form.gender = res.data.gender; // 性别
					this.form.phone = res.data.phone; // 手机号
					this.form.birthday = res.data.birthday; // 会员生日
					this.form.province = res.data.province; // 省
					this.form.city = res.data.city; // 市
					this.form.district = res.data.district; // 区
					this.form.remark = res.data.remark; // 备注
					this.form.location = [res.data.province, res.data.city, res.data.district]
				});
			}
		},
		methods: {
			// 所在地区改变事件
			locationChange(val) {
				console.log(val)
			},
			// 格式化省市区
			formatLocation(addr) {
				let x = [];
				for (let key in addr) {
					let obj = {};
					if (addr[key].cities) {
						// obj.value = key;
						obj.value = addr[key].name;
						obj.label = addr[key].name;
						obj.children = this.formatLocation(addr[key].cities);
					} else if (addr[key].districts) {
						// obj.value = key;
						obj.value = addr[key].name;
						obj.label = addr[key].name;
						obj.children = this.formatLocation(addr[key].districts);
					} else {
						// obj.value = key;
						obj.value = addr[key];
						obj.label = addr[key];
					}
					x.push(obj);
				}
				return x;
			},
			// 品牌服务tree改变
			brandCheckChange() {
				this.form.serve.brand = this.$refs.brandTree.getCheckedKeys()
			},
			// 自营服务tree改变
			selfCheckChange() {
				this.form.serve.self = this.$refs.selfTree.getCheckedKeys()
			},
			onSubmit() {
				this.$refs.form.validate(valid => {
					this.form.province = this.form.location[0]
					this.form.city = this.form.location[1]
					this.form.district = this.form.location[2]
					if (valid) {
						const loading = this.$loading();
						this.$api.member.save(this.form).then(res => {
								loading.close();
								if (res.code == 200) {
									// 保存成功
									this.$message.success(`保存成功`);
									this.$router.back();
								} else {
									this.$message.error(res.msg);
								}
							})
							.catch(err => {
								loading.close();
								this.$alert(err);
							});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}
</style>
